import React from 'react';
import {Helmet} from 'react-helmet';
import Logo from '../components/Logo';
import {StaticImage} from 'gatsby-plugin-image';

import ClubOverview from '../components/cluboverview/ClubOverview';
import ContactForm from '../components/form/ContactForm';
import QuestionsAndAnswers from '../components/QuestionsAndAnswers';
import Quote from '../components/Quote';
import Block from '../components/Block';
import InstagramLink from '../components/InstagramLink';
import FacebookLink from '../components/FacebookLink';
import SocialMediaBlock from '../components/SocialMediaBlock';
import ImpressionBlock from '../components/ImpressionBlock';
import YouTubeVideo from '../components/YouTubeVideo';

const ParentsPage = () => {
    return (
        <>
            <Helmet title="Eltern"/>
            <Logo title="Eltern"/>

            <Block variant="primary" image={(
                <StaticImage src="../images/impressions/u13_spieler_3.jpg" alt="Eltern" aspectRatio={1} width={400}/>
            )}>
                <p className="text-white">Sie suchen nach einer rasanten Mannschaftssportart für ihren Sohn oder ihre
                    Tochter? Ihre Kinder lieben das Inlinelaufen und sie sind auf der Suche nach einem sicheren Umfeld
                    zum Austoben? Sie möchten, dass ihr Kind Teil eines Teams wird, das zusammenhält und gemeinsam Ziele
                    verfolgt und erreicht?</p>

                <p className="text-white">Dann wird es Zeit, Teil der deutschlandweiten Skaterhockey-Familie zu werden.
                    Für unseren Nachwuchs leiten ausgebildete Trainer:innen die Übungseinheiten, alle Spiele werden von
                    professionellen Schiedsrichter:innen geleitet. Nehmen sie Kontakt zu einem naheliegenden Verein auf
                    und holen sie sich direkt Infos, wie ihr Kind so schnell und einfach wie möglich an einem geregelten
                    Trainings- und Spielbetrieb teilnehmen kann. Machen sie den ersten Schritt und lassen sie ihren Sohn
                    oder ihre Tochter an den Faszination Skaterhockey teilhaben!</p>
            </Block>

            <Block variant="light">
                <h2>Skaterhockey im Verein</h2>
                <p>Was macht Skaterhockey im Verein aus? Zusammenhalt, Spaß, gemeinsam Ziele erreichen und bis an die
                    Grenzen gehen.</p>
                <YouTubeVideo videoId="qpaiBPCmIGo"/>
            </Block>

            <Quote variant="primary"
                   image={<StaticImage src="../images/noebels_2.jpg" alt="Freundschaft" height={300}/>}
                   title="Freundschaft"
                   quote="Wenn ich an Skaterhockey denke, denke ich an Freundschaft, weil mir viele meiner Mitspieler über die Jahre ans Herz gewachsen sind."
                   caption="Marcel Noebels, Eishockey-Nationalspieler"/>

            <Block>
                <h2>Interaktive Karte</h2>
                <p>Skaterhockey ist eine Option für ihr Kind? Dann melden sie sich bei einem Verein ganz in ihrer Nähe
                    und fragen sie, ob ihr Kind ein Probetraining machen kann. Die dortigen Nachwuchstrainer:innen und
                    Betreuer:innen helfen gerne bei all ihren Fragen und unterstützen bei der Wahl von
                    Ausrüstungsgegenständen.</p>
                <ClubOverview/>
            </Block>

            <Quote variant="secondary"
                   image={<StaticImage src="../images/vater_zitat.jpg" alt="Ein toller Mannschaftssport" height={300}/>}
                   title="Ein toller Mannschaftssport"
                   quote={
                       <>
                           Meine Tochter Julia ist mit vier Jahren in die Laufschule gekommen. Danach hat sie in der
                           Bambinimannschaft begonnen, steht mittlerweile im Tor und hat dabei riesigen Spaß.
                           Skaterhockey ist ein toller Mannschaftssport. Der Zusammenhalt begeistert auch uns Eltern.
                           Auch wir engagieren uns, wenn im Verein Unterstützung gefragt ist
                       </>
                   }
                   caption="Horst S., Vater aus Atting"
            />

            <Block>
                <h2 id="contact">Kontakt</h2>
                <p>Sie haben Interesse an unserem Sport und möchten mehr erfahren? Sprechen sie uns
                    bei <FacebookLink/> oder <InstagramLink/> an oder nutzen sie das Kontaktformular!</p>
                <ContactForm topic="parents"/>
            </Block>

            <Block variant="light">
                <h2>Skaterhockey FAQ</h2>
                <p>Jede Menge nützliche Infos rund ums Skaterhockey</p>
                <QuestionsAndAnswers questions={[{
                    question: 'Ab welchem Alter ist ein Einstieg ins Skaterhockey sinnvoll?',
                    answer: 'Unsere jüngsten Skater:innen gehen mit vier oder fünf Jahren auf die Rollen. Für gewöhnlich beginnen die meisten Nachwuchsspieler:innen mit sechs bis acht Jahren.',
                }, {
                    question: 'Ist ein späterer Einstieg noch sinnvoll?',
                    answer: 'Bis zum Alter von 12 bis 14 Jahren ist ein Einstieg ins Skaterhockey in der Regel problemlos möglich. Zu einem späteren (Quer-)einstieg beraten sie gerne die Trainer:innen vor Ort.',
                }, {
                    question: 'Können auch Mädchen Skaterhockey ausprobieren?',
                    answer: 'Selbstverständlich. Mädchen trainieren zusammen mit den Jungs in einem Team. Einige Clubs haben auch Damenmannschaften im Trainings- oder Spielbetrieb.',
                }, {
                    question: 'Welche Ausrüstung ist erforderlich?',
                    answer: 'Inlineskates und Schutzausrüstung wie beim Eishockey.',
                }, {
                    question: 'Gibt es Einsteiger-Ausrüstungspakete?',
                    answer: 'Spezielle Onlineshops bieten Einsteiger-Ausrüstungspakete. Gebrauchte Ausrüstung gibt es bei den Clubs oder bei Onlineauktionshäusern. Manche Vereine bieten Leihausrüstungen an.',
                }, {
                    question: 'Wann kann man Skaterhockey spielen?',
                    answer: 'Die Sportart kann das ganze Jahr über gespielt werden. Die meisten Vereine spielen in eigenen Hallen (Turnhallen, Skaterhockey-Hallen, vereinzelt Eisstadien) oder auch auf Freiplätzen (diese sind dann witterungsabhängig).',
                }, {
                    question: 'Mein Kind spielt bereits Eishockey, der Leistungsdruck oder das zu erbringende Pensum neben der Schule sind aber zu hoch.',
                    answer: 'Dann ist ihr Kind im Skaterhockey genau richtig. Der Teamgedanke wird auch im Skaterhockey großgeschrieben, bei moderaten Leistungsanforderungen. Die Trainings- und Spielzeiten lassen sich außerdem hervorragend mit der Schule und anderen Aktivitäten verbinden.',
                }, {
                    question: 'Wie hoch ist das wöchentliche Trainingspensum?',
                    answer: 'Skaterhockey-Teams trainieren in der Regel zwei Mal pro Woche. Viele Vereine bieten Zusatzangebote an (Athletiktraining o.ä.).',
                }, {
                    question: 'Wann und wie oft finden Spiele statt?',
                    answer: 'Die Spiele finden in der Regel samstags oder sonntags statt. Die Saison beginnt im Frühjahr und endet im Herbst. Die Zahl der Ligaspiele variiert je nach Ligastärke.',
                }, {
                    question: 'Was zeichnet das Skaterhockey aus?',
                    answer: 'Skaterhockey ist ein Teamsport. Gemeinsam mit ihren Teamkollegen lernen die Kinder, auf Ziele hinzuarbeiten und diese zu erreichen. Neben den Trainingseinheiten und Spielen gehören auch außersportliche Aktivitäten zum Vereinsleben.',
                }]}/>
            </Block>

            <SocialMediaBlock/>

            <ImpressionBlock alt="Impressionen Eltern ">
                <StaticImage src="../images/impressions/u13_torjubel.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_trainer_huddle.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/u13_tor_szene.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_bully.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/kids_skating.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_schuss_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/eltern_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_kabine.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_spieler_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u10_huddle.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_schuss_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/eltern_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u16_bully.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_spieler_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u13_torwart_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/u19_tor_szene_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
            </ImpressionBlock>
        </>
    );
};

export default ParentsPage
